import { useState } from 'react'
import FullWidthContainer from '@components/layout/full-width-container'
import { Button } from '@components/ui/button'
import HighlightRightIcon from '@components/ui/icons/highlight-right-icon'
import { trackFlowEnded, trackFlowStarted } from '@lib/analytics'
import { getProcessedImage, type ProcessedImages } from '@lib/image'
import { ResponsiveImage } from '@components/ui/responsive-image'
import { UserInfoModal } from '@components/user-info-modal'
import { downloadFile } from '@lib/utils'
import josephHurley from '@modules/ebook/joseph-hurley.webp'
import marcSuhr from '@modules/ebook/marc-shur.webp'
import ebookCoverSvg from '@modules/ebook/ebook-cover.svg'
import ebookCoverImg from '@modules/ebook/ebook-cover-image.webp'
import { USER_TYPES } from '@lib/constants'

const FAMILY_GUIDE_URL =
  'https://web-resources.savingforcollege.com/guides/family_guide_2024_2025.pdf'

const ANALYTICS_FLOW_NAME = 'DownloadEbook'

interface AuthorInfoProps {
  name: string
  role: string
  image: ImageMetadata
  processedImages: ProcessedImages
}

const AuthorInfo = ({ name, role, image, processedImages }: AuthorInfoProps) => (
  <div className="flex gap-x-[15px]">
    <ResponsiveImage
      image={getProcessedImage(processedImages, image)}
      alt={`${name}'s profile`}
      width={50}
      height={50}
      className="shrink-0 h-[50px] w-[50px] rounded-full"
    />
    <div className="flex flex-col">
      <div className="text-body-main-bold">{name}</div>
      <div className="text-body-caption">{role}</div>
    </div>
  </div>
)

export const EbookSection = ({ processedImages }: { processedImages: ProcessedImages }) => {
  const [downloadDialogOpen, setDownloadDialogOpen] = useState(false)

  const handleDownload = () => {
    setDownloadDialogOpen(true)
    trackFlowStarted({ flowName: ANALYTICS_FLOW_NAME })
  }

  const onOpenChange = (isOpen: boolean) => {
    setDownloadDialogOpen(isOpen)

    if (!isOpen) {
      trackFlowEnded({ flowName: ANALYTICS_FLOW_NAME, lastStep: '', isCompleted: false })
    }
  }

  const onDownloadStarted = () => {
    setDownloadDialogOpen(false)
    trackFlowEnded({ flowName: ANALYTICS_FLOW_NAME, lastStep: '', isCompleted: true })
    setTimeout(() => downloadFile(FAMILY_GUIDE_URL), 500)
  }

  return (
    <section className="bg-blue-50 py-[100px]">
      <FullWidthContainer>
        <div className="flex justify-between gap-x-[60px]">
          <div className="flex flex-col w-full small-desktop:max-w-[550px]">
            <h2 className="text-title-lg small-desktop:text-title-lg-desktop mb-[5px]">
              Family Guide to College Savings
            </h2>
            <div className="flex items-center mb-[40px]">
              <span className="text-body-lead mr-[15px]">2024 — 2025 Edition</span>
              <span className="text-body-caption-bold justify-center mr-[10px] px-[10px] py-[4px] bg-white-500 rounded-lg">
                PDF
              </span>
              <span className="text-blue-500 h-[27px] small-desktop:h-[35px]">
                <HighlightRightIcon />
              </span>
            </div>
            <Button
              id="ebookCoverButton"
              variant="content"
              size="content"
              className="small-desktop:hidden mb-[20px] w-[335px] ml-[-14px]"
              onClick={handleDownload}
              aria-label="Download free E-book"
            >
              <EbookCover processedImages={processedImages} />
            </Button>
            <p className="w-full mb-[15px]">
              This guide is a must-read for all parents with college costs in their future. Whether
              you have toddlers or teenagers, this ebook will help you develop a simple and
              cost-effective strategy for saving and paying for college.
            </p>
            <div className="hidden small-desktop:flex mb-[35px] gap-[30px]">
              <AuthorInfo
                name="Joseph F. Hurley, CPA"
                role="Author"
                image={josephHurley}
                processedImages={processedImages}
              />
              <AuthorInfo
                name="Marc E. Suhr II"
                role="Editor"
                image={marcSuhr}
                processedImages={processedImages}
              />
            </div>
            <Button className="justify-center self-start" onClick={handleDownload}>
              Download free E-book
            </Button>
          </div>
          <Button
            id="ebookDownloadButton"
            variant="content"
            size="content"
            className="hidden small-desktop:block w-[370px]"
            onClick={handleDownload}
            aria-label="Download free E-book"
          >
            <EbookCover processedImages={processedImages} />
          </Button>
        </div>
      </FullWidthContainer>
      <UserInfoModal
        dialogOpen={downloadDialogOpen}
        onOpenChange={onOpenChange}
        userTypes={USER_TYPES}
        userTypePlaceholder="Please select your role..."
        trackSource="Homepage PC 529 Download"
        trackEmailSource="Homepage PC 529 Download"
        onActionCompleted={onDownloadStarted}
        dialogDescription="Please provide the information below to qualify for free download."
      >
        <span>
          <span>Family Guide to College Savings</span>
          <span className="inline-block align-middle ml-[12px]">
            <div className="flex px-[10px] py-[4px] bg-blue-100 rounded-lg h-[30px]">
              <span className="text-body-caption">PDF</span>
            </div>
          </span>
        </span>
      </UserInfoModal>
    </section>
  )
}

const EbookCover = ({ processedImages }: { processedImages: ProcessedImages }) => {
  return (
    <div className="relative w-[335px] h-[457px] small-desktop:w-[370px] small-desktop:h-[490px]">
      <img src={ebookCoverSvg.src} alt="" />
      <ResponsiveImage
        image={getProcessedImage(processedImages, ebookCoverImg)}
        width={300}
        height={172}
        alt=""
        className="absolute left-[9%] top-[22%] w-[75%]"
      />
    </div>
  )
}
