import { useState, useEffect } from 'react'
import { ResponsiveCombobox, type SearchOption } from '@components/ui/responsive-combobox'
import { US_STATES_MAP, US_STATES_SEARCH, type USState } from '@lib/us-states'

import { Button } from '@components/ui/button'
import HighlightLeftIcon from '@components/ui/icons/highlight-left-icon'
import HighlightRightIcon from '@components/ui/icons/highlight-right-icon'

type Props = {
  openGetStarted: (stateId?: string) => void
  userStateId: string | undefined
}

export const HeroSection = ({ userStateId, openGetStarted }: Props) => {
  const userState = userStateId ? US_STATES_MAP[userStateId] : undefined
  const [selectedState, setSelectedState] = useState<USState | undefined>(userState)
  const getStartedClicked = () => openGetStarted(selectedState?.abbr)

  useEffect(() => {
    if (!selectedState) setSelectedState(userState)
  }, [userState])

  return (
    <section className="flex flex-col items-center mt-[40px] small-desktop:mt-[70px] mb-[30px] small-desktop:mb-[40px]">
      <h1 className="font-title text-title-xl small-desktop:font-[600] small-desktop:leading-[110%] small-desktop:text-[90px] text-center mb-[15px]">
        Save smart with
        <br />
        <div className="flex items-center justify-center">
          <span className="inline-block align-middle mr-[10px] small-desktop:mr-[20px]">
            <div className="flex h-[35px] small-desktop:h-[50px] text-blue-500">
              <HighlightLeftIcon />
            </div>
          </span>
          a 529 plan
          <span className="inline-block align-middle ml-[10px] small-desktop:ml-[20px]">
            <div className="flex h-[35px] small-desktop:h-[50px] text-blue-500">
              <HighlightRightIcon />
            </div>
          </span>
        </div>
      </h1>
      <p className="small-desktop:text-body-lead small-desktop:max-w-[850px] px-[20px] text-center mb-[20px] small-desktop:mb-[40px]">
        We evaluate every 529 plan so you can pick the right one for your family.
      </p>
      <div className="flex flex-col items-stretch w-full max-w-[335px] small-desktop:flex-row gap-x-[10px] gap-y-[10px] small-desktop:max-w-[555px]">
        <ResponsiveCombobox
          options={US_STATES_SEARCH}
          selectedOption={
            US_STATES_SEARCH.find((option) => option.id === selectedState?.abbr) || null
          }
          onOptionChange={(option: SearchOption | null) => {
            if (option) {
              setSelectedState(US_STATES_MAP[option.id])
            }
          }}
          placeholder="Select a state"
          triggerButtonClassName="text-body-main-bold"
        />
        <Button
          id="getStartedButtonHero"
          size="lg"
          className="w-full small-desktop:max-w-[220px]"
          onClick={getStartedClicked}
        >
          Get started
        </Button>
      </div>
    </section>
  )
}
